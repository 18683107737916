import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofaI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

import { Twitter, Linkedin, Mail, Link, Clock } from "lucide-react";
import image1 from "../../assets/blog-images/unveiling-the/used-for-blog-1024x768-1.jpeg";

const UnveilingThe = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofaI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };
  return (
    <div className="pt-20 bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="text-secondary font-semibold mb-4">PROBLEM</div>

        <h1 className="text-3xl font-bold text-primary  mb-6">
          Unveiling the Inefficiencies in Traditional Maintenance Methods
        </h1>

        {/* <div className="flex items-center text-primary mb-6 space-x-4">
          <span>February 6, 2019</span>
        </div> */}

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary">Maintenance Strategies</span>
        </div>

        <img
          src={image1}
          alt="Industrial Machinery"
          className="w-full h-64 object-cover mb-6"
        />

        <p className="mb-8 text-primary ">
          As we delve further into the complexities of machine failures and
          disruptions in manufacturing facilities, it's evident that even the
          best maintenance strategies—such as RCM, FEMA, RBI, Failure
          Elimination, and Root Cause Analysis—fall short in preventing these
          issues. Despite rigorous implementation, these methods struggle to
          enhance reliability due to five critical factors:
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          1. Insufficient Data Collection
        </h2>

        <p className="mb-8 text-primary ">
          Many maintenance programs still rely on outdated practices, such as
          checking motor current readings sporadically with an ammeter. This
          approach is fundamentally flawed, as these readings often reveal
          nothing about impending failures. Induction motors, even with
          early-stage faults, may not draw more than their nameplate current. At{" "}
          <strong>minto.ai™</strong>, we capture thousands of current values per
          second to provide a comprehensive health assessment of the motor. Both
          the quantity and quality of data are paramount in predicting potential
          machine failures accurately.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          2. Infrequent Data Collection
        </h2>

        <p className="mb-8 text-primary ">
          Advanced health monitoring tools like the CSI 2140 lack the
          flexibility for frequent data gathering. This infrequency allows
          failures to occur undetected between monthly or weekly inspection
          cycles. Even with a highly skilled workforce, the rapid development of
          faults can result in unplanned downtime, as the infrequent data
          collection fails to catch issues in time.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          3. Limited Resources
        </h2>

        <p className="mb-8 text-primary ">
          Manufacturing facilities, especially those in sectors like
          steel/metals, pharmaceuticals, and power plants, often manage numerous
          assets, making manual data collection impractical. For instance, a
          mid-size manufacturing company might have 350 induction motors, while
          another section of a process plant might operate 179 pumps. Monitoring
          such a vast array of assets demands more personnel than available,
          leading to inconsistent data collection by different individuals.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          4. Accessibility Concerns
        </h2>

        <p className="mb-8 text-primary ">
          Critical machines, including vane pumps, ID fans, AHUs, submersible
          pumps, and conveyors in steel manufacturing facilities, are often
          challenging to access and pose significant dangers to technicians.
          Maintenance tools requiring close proximity to these machines for data
          collection are inherently ineffective in such hazardous environments.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          5. Variable Conditions and Principles
        </h2>

        <p className="mb-8 text-primary ">
          Operating conditions, such as load and environmental factors, can vary
          significantly. Identical induction motors may operate different
          machines with distinct operating principles. For example, a 10HP motor
          running a compressor will exhibit different characteristics compared
          to a 10HP motor running a pump. These variations can lead to
          inaccurate conclusions when existing maintenance tools and methods
          lack the intelligence to adapt to these differing conditions.
        </p>

        <h2 className="text-2xl font-bold text-primary  mb-4">
          The Path Forward
        </h2>

        <p className="mb-8 text-primary ">
          These challenges highlight the inefficacy of traditional maintenance
          tools and methods in reducing machine downtime and enhancing
          availability. In our final article of this series, we will explore how
          an AI-driven IoT product can address these issues, significantly
          reducing machine downtime and achieving an impressive 99.999%
          availability. This forthcoming discussion will also guide asset owners
          in making informed investments in suitable IoT solutions.
        </p>

        <p className="mb-8 text-primary ">
          By addressing these pain points with cutting-edge technology,{" "}
          <strong>minto.ai™</strong> is poised to revolutionize industrial
          maintenance, making machine failures a thing of the past. Stay tuned
          for insights that could transform your approach to asset management
          and reliability.
        </p>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnveilingThe;
