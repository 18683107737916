import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Twitter, Linkedin, Mail, Link, Clock } from "lucide-react";

import image1 from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import image2 from "../../assets/blog-images/embrace-evolution/newplot_(2).png";
import image3 from "../../assets/blog-images/embrace-evolution/newplot_(3).png";
import image4 from "../../assets/blog-images/embrace-evolution/newplot.png";
import image5 from "../../assets/blog-images/embrace-evolution/newplot_(1).png";
import image6 from "../../assets/blog-images/embrace-evolution/newplot_(4).png";
import image7 from "../../assets/blog-images/embrace-evolution/65b8dec4-05b2-44d1-ae5e-bfcff853d2d9.png";
import image8 from "../../assets/blog-images/embrace-evolution/Diagnose.png";

import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofAI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

const EmbraceEvolution = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofAI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  return (
    <div className="pt-20 bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary mb-6">
          Embracing Evolution: From Domesticators to Innovators with spiderAI™
        </h1>

        {/* <div className="flex items-center text-primary mb-6 space-x-4">
          <span>May 18, 2024</span>
        </div> */}

        <div className="text-primary mb-8">
          Topic: <span className="text-secondary">Product Release</span>
        </div>

        <img
          src={image1}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          A Journey Through Human Technological Evolution
        </h2>

        <p className="mb-8 text-primary">
          Humanity's journey from hunter-gatherers to technological pioneers is
          a testament to our species' ingenuity and resilience. Our relationship
          with tools is very old; in 2011, researchers with iPhones in their
          pockets, looking for samples of Acheulean industry around Lake Turkana
          in East Africa's Rift, stumbled across a trove of rough stone tools,
          which they estimate to be 700,000 years older than any previously
          discovered.
        </p>

        <p className="mb-8 text-primary">
          Approximately 15,000 years ago, our ancestors began domesticating
          animals, utilizing them as the first "machines" to capture energy and
          aid in daily labor. This marked the dawn of a transformative
          relationship between humans and technology.
        </p>

        <h2 className="text-2xl font-bold text-primary mb-4">
          The Industrial Revolution: A Paradigm Shift
        </h2>

        <p className="mb-8 text-primary">
          The advent of the Industrial Revolution in the 18th century marked a
          seismic shift in how humans interacted with technology. Energy-hungry
          machines replaced animal and human labor, revolutionizing production
          processes and reducing manual toil. This era witnessed unprecedented
          advancements in manufacturing, transportation, and communication,
          propelling humanity into a new age of progress.
        </p>

        <img
          src={image2}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          The Irony of Modern Toil
        </h2>

        <p className="mb-8 text-primary">
          Despite these technological advancements, millions of people around
          the world continue to toil in fields, factories, and maintenance
          roles. Ironically, the very machines designed to alleviate human
          burdens have become sources of toil and inefficiency in their lives.
        </p>

        <img
          src={image3}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          The Promise of Industry 4.0
        </h2>

        <p className="mb-8 text-primary">
          The concept of Industry 4.0 promised a future where smart,
          interconnected systems would revolutionize industries. However, a
          critical gap persisted: the seamless integration of information,
          knowledge, and expertise for real-time, accurate decision-making.
        </p>
        <img
          src={image4}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          The Dawn of a New Era: Large Language Models
        </h2>

        <p className="mb-8 text-primary">
          Enter the era of large language models (LLMs). These advanced AI
          systems have the potential to bridge the gap left by previous
          technological advancements. LLMs can understand, interpret, and reason
          with vast amounts of data, enabling highly accurate real-time
          decision-making.
        </p>

        <img
          src={image5}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          Introducing spiderAI™: Your Assistive Copilot
        </h2>

        <p className="mb-8 text-primary">
          We are thrilled to announce the beta release of spiderAI™, an
          assistive copilot designed to revolutionize how we interact with
          industrial machines and equipment. spiderAI™ enables users to
          communicate and reason with machines in simple language, dramatically
          improving decision-making accuracy, productivity, and efficiency.
        </p>

        <video
          className="w-full max-h-full rounded-lg border-gray-300 mb-6"
          controls
          preload="auto"
        >
          <source
            src="https://s3.us-east-2.amazonaws.com/minto.ai-resources/spiderAI/short10+(1).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="flex justify-center mb-8">
          <a
            href="https://www.youtube.com/watch?v=jeZeS1-UnoY"
            className="inline-flex items-center font-medium hover:underline text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            View on YouTube{" "}
            <svg
              className="w-4 h-4 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>

        <h2 className="text-2xl font-bold text-primary mb-4">
          Transforming Workflows and Enhancing Roles
        </h2>

        <p className="mb-8 text-gprimary">
          Our vision for spiderAI™ is to make the roles of those who operate and
          maintain machines more meaningful, creative, and satisfying. By acting
          as an assistive copilot, spiderAI™ interprets users' questions and
          reasons alongside them, facilitating highly accurate decision-making.
        </p>

        <img
          src={image6}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          Join Our Beta Program
        </h2>

        <p className="mb-8 text-primary">
          We are excited to invite maintenance engineers, reliability
          consultants, APM consultants, condition monitoring engineers, and
          vibration analysts to participate in our beta program. If you are
          interested in exploring the capabilities of spiderAI™, please click
          the below link or mail us at spiderai@minto.ai
        </p>

        <div className="flex justify-center mb-8">
          <a
            href="https://www.minto.ai/getaccess"
            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium rounded-full bg-black border-2 border-black text-white hover:bg-primary hover:border-primary transition-colors duration-300 no-underline"
          >
            Click Here
          </a>
        </div>

        <h2 className="text-2xl font-bold text-primary mb-4">
          Integrating spiderAI™ with iHz™, our MCSA based IoT device
        </h2>

        <p className="mb-8 text-primary">
          We have also integrated spiderAI™ into iHz™, our IoT device using
          Motor Control Signature Analysis, available to selected customers.
          This integration represents a significant step towards democratizing
          intelligent decision-making in industrial settings.
        </p>
        <img
          src={image7}
          alt="Evolution of Human Technology"
          className="w-full h-full object-cover mb-6"
        />

        <h2 className="text-2xl font-bold text-primary mb-4">
          Stay Tuned for More
        </h2>

        <p className="mb-8 text-primary">
          As we continue to develop and refine spiderAI™, we are committed to
          keeping you informed with exciting updates. Our journey towards
          transforming the industrial landscape is just beginning, and we are
          eager to share our progress with you.
        </p>

        <div className="text-2xl font-bold text-primary mb-8">
          spiderAI™ - Talk with Machines
        </div>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-primary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmbraceEvolution;
