import React, { useState, useEffect } from "react";
import {
  Twitter,
  Linkedin,
  Mail,
  Link,
  Clock,
  ExternalLink,
} from "lucide-react";

import { useNavigate } from "react-router-dom";
import image1 from "../../assets/blog-images/bokaro/TOI-Article-1.png";
import image2 from "../../assets/blog-images/bokaro/Steel.png";

import embrace from "../../assets/blog-images/embrace-evolution/image1-evol.webp";
import bokaro from "../../assets/blog-images/bokaro/bokaro.jpg";
import FeaturesofAI from "../../assets/blog-images/features-of-ai/unplanned_dt.jpg";
import MintoRecognisedas from "../../assets/blog-images/minto-recognised-as/PHOTO-2019-11-28-12-45-32-2-1-1024x576-1.jpg";
import ProFoundImpact from "../../assets/blog-images/profound-impact/profound_impact.jpg";
import unveilingThe from "../../assets/blog-images/unveiling-the/unveiling.jpg";

const Bokaro = () => {
  const blogPostsData = [
    {
      author: "spiders",
      categories: ["Product"],
      date: "2019-02-21T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Features of an AI-Driven IoT Product That Solves the Problem of Unplanned Downtime",
      description:
        "In our previous articles, we've discussed the significant financial losses manufacturing companies and asset owners face due to unplanned downtime and why existing maintenance methods fall short. In this final article of the series, we will explore the features of an AI-driven IoT product that can dramatically reduce downtime and extend the lifespan of machines, providing a compelling investment opportunity for asset owners.",
      sortOrder: 5,
      featuredImage: FeaturesofAI,
      route: "/blog/features-of-ai-driven-iot-product",
    },
    {
      author: "spiders",
      categories: ["Value"],
      date: "2019-09-03T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title: "The Profound Impact of minto.ai™ Beyond Monetary Benefits",
      description:
        "In my journey with minto.ai™, I often pondered whether our product truly makes a difference in the world and brings meaningful change to people's lives. Beyond the financial gains, are we adding significant value to individuals and organizations?",
      sortOrder: 4,
      featuredImage: ProFoundImpact,
      route: "/blog/profound-impact-of-minto-ai",
    },
    {
      author: "spiders",
      categories: ["Awards"],
      date: "2019-06-12T00:00:00.000Z",
      featured: false,
      image: "../../assets/favicon.ico",
      title:
        "minto.ai™ recognized as top 2 innovative startups in the country by CK Birla group",
      description:
        "minto.ai is recognized by Birlasoft as one of the top two startups and runner-up across the country in the grand Finale happened at Birlasoft Xccelerate4startups event in Noida.",
      sortOrder: 3,
      featuredImage: MintoRecognisedas,
      route: "/blog/minto-ai-recognized-as-top-2-innovative-startups",
    },
    {
      author: "spiders",
      categories: ["Customers"],
      date: "2021-11-24T00:00:00.000Z",
      featured: false,
      image: "../../assets/people/ape-ravi.jpeg",
      title:
        "Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of AI-based Predictive Monitoring system",
      description:
        "We at minto.ai™ started the deployment of its Industrial IoT system connected to its platform **spidersense™** in steel plants. With the ongoing and future deployments in Steel Plants, we have entered an important segment where we believe there is a need to add value with our Industrial IoT platform called **spidersense™.**",
      sortOrder: 2,
      featuredImage: bokaro,
      route: "/blog/bokaro-steel-plant-partners-with-minto-ai",
    },
    {
      author: "Raviteja Valluri",
      categories: ["Product"],
      date: "2024-05-18T00:00:00.000Z",
      featured: false,
      image: embrace,
      title:
        "Embracing Evolution From Domesticators to Innovators with spiderAI™",
      description:
        "Humanity's journey from hunter-gatherers to technological pioneers is a testament to our species' ingenuity and resilience. Our relationship with tools are very old that in 2011, researchers",
      sortOrder: 1,
      featuredImage: embrace,
      route: "/blog/embracing-evolution-with-spider-ai",
    },
    {
      author: "spiders",
      categories: ["Problem"],
      date: "2019-02-06T00:00:00.000Z",
      featured: false,
      title: "Unveiling the Inefficiencies in Traditional Maintenance Methods",
      description:
        "As we delve further into the complexities of machine failures and disruptions in manufacturing facilities, it's evident that even the best maintenance strategies—such as RCM, FEMA, RBI, Failure Elimination, and Root Cause Analysis—fall short in",
      sortOrder: 6,
      image: "../../assets/people/ape-ravi.jpeg",
      featuredImage: unveilingThe,
      route:
        "/blog/unveiling-inefficiencies-in-traditional-maintenance-methods",
    },
  ];

  const navigate = useNavigate();
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    const shuffled = [...blogPostsData].sort(() => 0.5 - Math.random());
    setRelatedArticles(shuffled.slice(0, 3));
  }, []);

  const handleArticleClick = (route) => {
    navigate(route);
  };

  const mediaLinks = [
    {
      name: "Analytics India",
      url: "https://analyticsindiamag.com/bokaro-steel-plant-begins-trial-of-its-ai-based-predictive-monitoring-system/",
    },
    {
      name: "Daily Pioneer",
      url: "https://www.dailypioneer.com/2021/state-editions/trial-of-ai-based-predictive-monitoring-system-done-in-bsl.html",
    },
    {
      name: "Times of India",
      url: "https://timesofindia.indiatimes.com/city/ranchi/bsl-begins-trial-of-ai-based-predictive-monitoring-system/articleshow/87704614.cms?from=mdr",
    },
    {
      name: "India ai",
      url: "https://indiaai.gov.in/news/bokaro-steel-begins-ai-based-predictive-monitoring",
    },
    {
      name: "India Next",
      url: "https://www.indianext.co.in/bokaro-steel-begins-ai-based-predictive-monitoring/",
    },
  ];

  return (
    <div className="pt-20 bg-white">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary mb-6">
          Bokaro Steel Plant(SAIL) partners with minto.ai™ to begin trials of
          AI-based Predictive Monitoring system
        </h1>

        {/* <div className="flex items-center text-primary mb-6 space-x-4">
          <span>November 24, 2021</span>
        </div> */}

        <div className="text-primary mb-8">
          Topic:{" "}
          <span className="text-secondary">Industrial IoT, Steel Industry</span>
        </div>

        <p className="mb-8 text-primary">
          We at minto.ai™ started the deployment of its Industrial IoT system
          connected to its platform <strong>spidersense™</strong> in steel
          plants. With the ongoing and future deployments in Steel Plants, we
          have entered an important segment where we believe there is a need to
          add value with our Industrial IoT platform called{" "}
          <strong>spidersense™</strong>.
        </p>

        <div className="mb-8">
          <img
            src={image1}
            alt="As featured in Times Of India"
            className="w-full h-auto rounded-lg"
          />
          <p className="text-sm text-primary mt-2">
            Image 1: As featured in Times Of India - Please click the image to
            read the full story
          </p>
        </div>

        <h2 className="text-2xl font-bold text-primary mb-6">
          Significance of Steel Industry
        </h2>

        <ul className="list-disc pl-6 mb-8 text-primary">
          <li>
            3.8% share of annual global GDP accounted for by the steel industry
            and 10.7% share of annual global GDP considering the indirect
            impact.
          </li>
          <li>
            Every $1 of value that is added by work within the steel industry
            itself, a further $2.50 of value-added activity is supported across
            other sectors of the global economy, as a result of purchases of raw
            materials, goods, energy, and services.
          </li>
          <li>
            For every two jobs in the steel sector, 13 more jobs are supported
            throughout its global supply chain. In total, some 40 million people
            work within this supply chain.
          </li>
        </ul>

        <div className="mb-8">
          <img
            src={image2}
            alt="A steel plant in action"
            className="w-full h-auto rounded-lg"
          />
          <p className="text-sm text-primary mt-2">
            Image 2: A steel plant in action
          </p>
        </div>

        <h2 className="text-2xl font-bold text-primary mb-6">
          The Need for Industrial IoT Technologies in Steel Plants
        </h2>

        <ul className="list-disc pl-6 mb-8 text-primary">
          <li>
            In Steel industry, maintenance cost accounts for 10 - 15% of
            production cost. Maintenance affects the target, quality and
            profitability of the plant.
          </li>
          <li>
            Electrical Rotating Machines (ERM) are very critical assets in steel
            plants especially in the Blast Furnace, sinter plant, hot-strip
            mill, coal handling plant, wire rod mills etc.
          </li>
          <li>
            Implementation of <strong>Industrial IoT technologies</strong> in
            the Maintenance of ERMs can not only improve availability but also
            reduce maintenance costs and enhance reliability of machine
            performance and quality of the output.
          </li>
          <li>
            Under the current business environment, cost competitiveness of
            steelmakers has assumed a priority role. As a global phenomenon,
            effective maintenance management has been accepted as the key to
            corporate strategy for reduced costs.
          </li>
          <li>
            <strong>
              According to industry estimates, a 10 percent reduction in
              maintenance costs translates to a 30 percent increase in
              profitability
            </strong>
            .
          </li>
        </ul>

        <h2 className="text-2xl font-bold text-primary mb-6">
          Challenges in Implementing Industrial IoT Technologies in Steel Plants
        </h2>

        <ul className="list-disc pl-6 mb-8 text-primary">
          <li>Machines are very difficult to access</li>
          <li>
            Monitoring machines that undergo constant stress amid heat, water &
            scale.
          </li>
          <li>
            Monitoring equipment/IoT devices have to function seamlessly in the
            plant under the hot ambient temperatures.
          </li>
          <li>Availability of wireless-communication infrastructure.</li>
        </ul>

        <h2 className="text-2xl font-bold text-primary mb-6">
          minto.ai™'s Solution: spidersense™
        </h2>

        <ul className="list-disc pl-6 mb-8 text-primary">
          <li>
            <strong>spidersense™</strong>, the Smart & Real-Time monitoring
            platform for electrical rotating machines (ERM) is an Industry4.0
            product built by minto.ai™ to help process manufacturing plants to
            reduce unplanned downtime and improve throughput/availability &
            energy efficiency
          </li>
          <li>
            <strong>spidersense™</strong> with its{" "}
            <strong>unique technology based on Electrical Signatures</strong>{" "}
            (sensors don't require access to the machine's location) monitors
            the health of these ERMs in real-time and helps the maintenance
            engineers to not only reduce unplanned breakdowns but also improve
            operational efficiency.
          </li>
          <li>
            <strong>spidersense™</strong> leverages machine learning & IoT to
            detect the equipment health degradation issues in ERMs before they
            fail so that the plant team's can schedule the interventions
            proactively.
          </li>
          <li>
            <strong>spidersense™</strong> is{" "}
            <strong>
              built on better technology, simple to use & understand and
              efficient product
            </strong>{" "}
            that not only upskills maintenance engineers but also helps them to
            optimise their operations.
          </li>
        </ul>

        <div className="bg-gray-100 p-4 rounded-lg mb-8">
          <p className="text-primary">
            <strong>Note:</strong> Motors and generators which are commonly
            called as Electrical Rotating Machines are ubiquitous and are
            critical to run our world. 47% of the world's produced energy is
            being consumed by electric motors, which are also the fulcrum of the
            industrial processes. Failure of these ERMs cause significant
            damages ranging from unplanned outages to the inefficient operations
            which adversely impacts the plant's throughput & production quality
            and ultimately the profits.
          </p>
        </div>

        <h2 className="text-2xl font-bold text-primary mb-6">Media Coverage</h2>

        <ul className="list-none pl-0 mb-8">
          {mediaLinks.map((link, index) => (
            <li key={index} className="mb-2">
              <a
                href={link.url}
                className="text-primary hover:underline flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
                <ExternalLink size={14} className="ml-1" />
              </a>
            </li>
          ))}
        </ul>

        <h2 className="text-2xl font-bold text-primary mb-6">References</h2>

        <ul className="list-decimal pl-6 mb-8 text-primary">
          <li>
            <a
              href="https://www.worldsteel.org/en/dam/jcr:fdf44918-de3b-455b-9083-f770afa4a214/OE%2520Executive%2520Summary.pdf"
              className="text-primary hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Role of Steel in the Global Economy - Report for World Steel
              Association
            </a>
          </li>
          <li>
            <a
              href="https://partners.wsj.com/emerson/unlocking-performance/how-manufacturers-can-achieve-top-quartile-performance/"
              className="text-primary hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unlocking Performance - How Manufacturers can achieve top quartile
              performance
            </a>
          </li>
          <li>
            <a
              href="https://www.ndt.net/article/wcndt00/papers/idn135/idn135.htm"
              className="text-primary hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Development and Implementation of Reliability centered Maintenance
              using Vibration Analysis: Experiences at Rourkela Steel Plant
            </a>
          </li>
        </ul>
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-24 pb-6 sm:pb-8 md:pb-10">
        <div className="mt-8 sm:mt-12 md:mt-16 px-4 sm:px-8 md:px-16 lg:px-24">
          <h2 className="text-xl sm:text-2xl font-bold text-secondary mb-6 sm:mb-8">
            Related Articles
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
            {relatedArticles.map((article, index) => (
              <div
                key={index}
                className="flex flex-col cursor-pointer"
                onClick={() => handleArticleClick(article.route)}
              >
                <img
                  src={article.featuredImage}
                  alt={article.title}
                  className="w-full h-48 object-cover mb-4"
                />
                <div className="text-xs sm:text-sm font-semibold text-primary mb-2">
                  {article.categories[0]}
                </div>
                <h3 className="text-base sm:text-lg font-bold text-primary mb-2">
                  {article.title}
                </h3>
                <div className="mt-auto">
                  <div className="text-xs sm:text-sm text-primary mt-1 flex">
                    <span>Topic: </span>
                    <div className="text-secondary ml-1">
                      {article.categories.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bokaro;
