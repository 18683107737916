import React, { useState } from "react";
import ihz from "../assets/Hero_ihz2.jpg";
import spiderAI from "../assets/spiderAI-screen.jpg";
import ImageCard from "./ImageCard";
import { useNavigate } from "react-router-dom";
import { getCDNUrl } from "../utils/cdn";

const EDFArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="absolute top-0 right-0 w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 translate-x-full"
  >
    <defs>
      <symbol viewBox="0 0 40 40" id="svgs-icon-edf-arrow">
        <path fill="#001A70" d="M0 40h40V0H0z" />
        <path
          fill="#1056C8"
          d="M13.805 5.364L11.559 7.61 23.949 20l2.246-2.246z"
        />
        <path
          fill="#1089FF"
          d="M11.559 32.39l2.246 2.246 12.39-12.39L23.949 20z"
        />
        <path fill="#FFF" d="M26.195 17.754L23.949 20l2.246 2.246L28.441 20z" />
      </symbol>
    </defs>
    <use href="#svgs-icon-edf-arrow" />
  </svg>
);

const ProductCards = () => {
  const navigate = useNavigate();

  const products = [
    {
      title: "spiderAI™ - Operational Intelligence Platform",
      subTitle: "Software",
      image: getCDNUrl("/spiderAI-screen.jpg"),
      url: "/spiderai",
      features: [
        "Conversational AI interface",
        "Multi-source data integration",
        "Intelligent insights and recommendations",
        "Intelligent Notification Engine",
      ],
    },
    {
      title: "iHz™ - Total Equipment Intelligence",
      subTitle: "Industrial IoT System",
      image: getCDNUrl("/Hero_ihz2.jpg"),
      url: "/ihz",
      features: [
        "High Fidelity electrical signatures acquisition",
        "High-resolution equipment health analysis",
        "Real-time anomaly detection for diverse machinery",
        "Diagnostic algorithms",
      ],
    }

  ];

  const handleCardClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="w-full bg-primary-background py-12">
      <div className="max-w-[1920px] mx-auto px-[5%]">
        <div className="mb-12">
          <h2 className="text-3xl font-bold text-primary mb-2">
            Discover Our Products
          </h2>
          <div className="h-1 bg-secondary w-24 mb-6"></div>
          <p className="text-lg text-primary">
            Build with a Mission : Accurate and Reliable technology systems for
            Total Equipment Intelligence
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="relative overflow-hidden shadow-lg h-[300px] sm:h-[400px] lg:h-[500px] cursor-pointer transform transition-transform duration-200 hover:scale-[1.02]"
              onClick={() => handleCardClick(product.url)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleCardClick(product.url);
                }
              }}
            >
              <img
                src={product.image}
                alt={`${product.title} - ${product.subTitle}`}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-6 left-6">
                <div className="relative bg-blue-600 w-[70%] max-w-[400px]">
                  <div className="p-6">
                    <div className="w-full">
                      <h3 className="text-white font-semibold text-lg sm:text-xl md:text-2xl leading-tight mb-2">
                        {product.title}
                      </h3>
                      <p className="text-white text-sm sm:text-base">
                        {product.subTitle}
                      </p>
                    </div>
                  </div>
                  <EDFArrow />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
